import React from 'react';
import { useNavigate } from 'react-router-dom';



let Scroll = require('react-scroll');
let scroll = Scroll.animateScroll;
 let homePath = "/"

 export default function Header() {
  let navHome = useNavigate()

  return (
    <header className="header">
      <div className='logo-txt' onClick={() =>window.location.pathname === homePath ? scroll.scrollToTop() : navHome('/')}>Нижегородская <br/>Холодильная<br/> Лига</div>
       <a className='number' href="tel:+79519084213">+7 (951) 908-42-13</a>      
    </header>
  );
}

import { Routes, Route } from 'react-router-dom';
import './App.scss';
import useSiteConfig from './Config/siteConfig';
import SiteLayout from './00_site_layout/SiteLayout';
import HomePage from './01_pages/HomePage/HomePage';
import NotFoundPage from './01_pages/NotFoundPage/NotFoundPage';
import SettingsPage from './01_pages/SettingsPage/SettingsPage';
import AboutUsPage from './01_pages/AboutUsPage/AboutUsPage';

import ArticlesPage from './01_pages/ArticlesPage/ArticlesPage';
import HowToChoose from './01_pages/Articles/HowToChoose/HowToChoose';
import WhatIsChiller from './01_pages/Articles/WhatIsChiller/WhatIsChiller';
import ShockFreezing from './01_pages/Articles/ShockFreezing';
import Conditioner from './01_pages/Articles/Conditioner/Conditioner';
import IceMachines from './01_pages/Articles/IceMachines/IceMachines';

import ServicesPage from './01_pages/ServicePage/ServicesPage';
import RefEqRepair from './01_pages/Services/RefEqRepair';
import ShockFreezRepair from './01_pages/Services/ShockFreezRepair';
import ChillRepair from './01_pages/Services/ChillRepair';
import LegalNoticePage from './01_pages/LegalNoticePage/LegalNoticePage';
import PreLoader from './03_components/PreLoader/PreLoader'

function App() {
  const updateSiteConfig = useSiteConfig();
  return (
    <>
    {/* <PreLoader/> */}
      <Routes>
        <Route path="/" element={<SiteLayout />}>
          <Route index element={<HomePage />}></Route>
          <Route path="articles" element={<ArticlesPage />}>
            <Route
              path="kak-vibrat-holodilnoe-oborudovanie"
              element={<HowToChoose />}
            />
            <Route path="chto-takoe-chiller" element={<WhatIsChiller />} />
            <Route path="shokovaya-zamorozka" element={<ShockFreezing />} />
            <Route path="conditioners" element={<Conditioner />} />
            <Route path="ldogeneratori" element={<IceMachines />} />
          </Route>
          <Route path="services" element={<ServicesPage />}>
            <Route
              path="remont-holodilnogo-oborudovaniya"
              element={<RefEqRepair />}
            />
            <Route path="remont-chillera" element={<ChillRepair />} />
            <Route
              path="remont-kamer-shokovoy-zamorozki"
              element={<ShockFreezRepair />}
            />
          </Route>
          <Route
            path="settings"
            element={<SettingsPage updateSiteConfig={updateSiteConfig} />}
          ></Route>
          <Route path="about" element={<AboutUsPage />}></Route>
          <Route path="legal-notice" element={<LegalNoticePage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
